.headerImage {
  background-image: url("https://candmwanderings.wordpress.com/wp-content/uploads/2015/08/cropped-img_7579.jpg");
  background-position: center;
  background-size: auto 100%;
  background-repeat: no-repeat;
  height: 300px;

  vertical-align: center;
  align-content: center;
}

h1#name {
  color: white;
  font-size: 60px;
}
h2#subtitle {
  color: white;
  font-size: 30px;
}

.card {
  border: none !important;
  box-shadow: none !important;
}

table, tr, td {
  border-top: 0 !important;
  border-bottom: 0 !important;
  border-left: 0 !important;
  border-right: 0 !important;
}
.key {
  width: 100px;
  text-align: right !important;
  border-right: black 1px solid;
}

.value {
  width: 100px;
}

button.secondary, a.secondary {
  margin-top: 20px !important;
}
